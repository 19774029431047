<template>
	<div class="box">
		<div class="main">
			<div class="list" v-if="certificateTwo">
				<img :src="certificateTwo" @click="downZhengShuFn(certificateTwo)" />
			</div>
			<div class="list" v-if="certificateOne">
				<img :src="certificateOne" @click="downZhengShuFn(certificateOne)" />
			</div>
		</div>
		<div class="centered-text" style="margin-top:15%" v-if="!certificateOne && !certificateTwo">
			证书审核中
		</div>
	</div>
</template>

<script>
	import service from '@/utils/request.js';
	export default {
		props: {},
		data() {
			return {
				certificateOne: "", //创新证书  
				certificateTwo: "" //研学证书
			}
		},
		mounted() {
			let that = this;
			//创新证书
			this.$http.post('/api/user/study', {

			}).then(response => {
				// 处理响应数据
				if (response.status == 200) {
					that.certificateOne = response.data.img ? "https://admin.qddgzs.cn" + response.data.img : "";
				}
			});
			//研学证书
			this.$http.post('/api/user/learn', {}).then(response => {
				// 处理响应数据
				if (response.status == 200) {
					that.certificateTwo = response.data.img ? "https://admin.qddgzs.cn" + response.data.img : "";
				}
			});
		},
		methods: {
			//下载证书
			downZhengShuFn(src) {
				if (src) {
					window.location.href = src;
				}
			},
		},
		components: {

		},
	}
</script>

<style scoped lang="less">
	.box {
		width: 100%;
		min-height: 100%;
		background-color: #fff;

		.main {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 50px 100px;
			box-sizing: border-box;

			.list {
				width: 45%;

				img {
					width: 100%;
					height: 100%;
				}
			}


		}

		.centered-text {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
</style>