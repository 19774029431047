import { render, staticRenderFns } from "./password.vue?vue&type=template&id=c8187184&scoped=true&"
import script from "./password.vue?vue&type=script&lang=js&"
export * from "./password.vue?vue&type=script&lang=js&"
import style0 from "./password.vue?vue&type=style&index=0&id=c8187184&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8187184",
  null
  
)

export default component.exports