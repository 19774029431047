import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login.vue'
import Home from '@/components/Home.vue'
import Welcome from '@/components/Welcome.vue'
import loginTwo from '@/components/loginTwo.vue'
import message from '@/components/message.vue'
import download from '@/components/download.vue'
import upload from '@/components/upload.vue'
import search from '@/components/search.vue'
import password from '@/components/password.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/login',
		component: Login
	},
	{
		path: '/password',
		component: password
	},
	{
		path: '/loginTwo',
		component: loginTwo
	},
	{
		path: '/',
		redirect: '/loginTwo'
	},

	{
		path: '/home',
		component: Home,
		redirect: '/Welcome',
		children: [{
				path: '/Welcome',
				component: Welcome
			},
			{
				path: '/message',
				component: message
			},
			{
				path: '/download',
				component: download
			},
			{
				path: '/upload',
				component: upload
			},
			{
				path: '/search',
				component: search
			},
		],
	},
]

const router = new VueRouter({
	routes,
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

router.beforeEach((to, form, next) => {
	if (to.path === '/login' || to.path === '/loginTwo' || to.path === '/password') return next()
	const tokenStr = window.sessionStorage.getItem('token')
	if (!tokenStr) return next('/loginTwo')
	next()
})

export default router