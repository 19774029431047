<template>
  <el-container class="home">
    <!-- 头部区域 -->
    <el-header>
      <div class="image">
        <img src="../assets/logo1.png" alt="" />
      </div>
      <!-- <div class="title" v-for="(item, index) in menulist" :key="index">
			<div @click="saveNavState(item.path)">
			  {{ item.title }}
			</div>
			</div> -->
      <div class="title">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          background-color="#206ad7"
          text-color="#fff"
          active-text-color="orange"
          unique-opened
        >
          <el-menu-item
            v-for="(item, index) in menulist"
            :key="index"
            @click="saveNavState(item.path)"
            :index="item.index"
            >{{ item.title }}</el-menu-item
          ></el-menu
        >
      </div>
      <div class="btn" v-if="!islogin">
        <div class="login" @click="loginTwo()">登录</div>
        <div class="register" @click="login()">注册</div>
      </div>
      <div class="btn" v-if="islogin">
        <div class="login" @click="loginOut()">退出</div>
      </div>
    </el-header>
    <!-- 页面主体区域 -->

    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      // 左侧菜单数据
      menulist: [
        {
          title: "信息收集",
          index: "1",
          path: "/message",
        },
        {
          title: "下载研学报告",
          index: "2",
          path: "/download",
        },
        {
          title: "上传研学报告",
          index: "3",
          path: "/upload",
        },
        {
          title: "证书查询",
          index: "4",
          path: "/search",
        },
      ],
      islogin: false,
    };
  },
  mounted() {
    let token = window.sessionStorage.getItem("token");
    this.islogin = token ? true : false;
  },
  methods: {
    loginOut() {
      window.sessionStorage.setItem("token", "");
      window.sessionStorage.setItem("customerInfo", "");
      window.sessionStorage.setItem("expirationTime", "");
      window.sessionStorage.setItem("fivexpirationTime", "");
      // 清空sessionStorage中的所有数据
      window.sessionStorage.clear();
      this.$router.push("/loginTwo");
    },
    loginTwo() {
      let token = window.sessionStorage.getItem("token");
      console.log(token);
      if (token) {
        this.$message.error({
          message: "已经登录",
          type: "warning",
        });
        return false;
      }
      this.$router.push("/loginTwo");
    },
    login() {
      let token = window.sessionStorage.getItem("token");
      if (token) {
        this.$message.error({
          message: "已经登录",
          type: "warning",
        });
        return false;
      }
      this.$router.push("/login");
    },
    saveNavState(item) {
      this.$router.push(item);
    },
  },
  components: {},
};
</script>

<style scoped lang="less">
.home {
  width: 100%;
  height: 100%;
}

.el-header {
  background-color: #206ad7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0 80px;
  font-size: 16px;

  .image {
    flex: 1;
    display: flex;
    align-items: center;

    img {
      height: 35px;
    }
  }

  .title {
    flex: 3;

    .el-menu-demo {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .btn {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
  }
}

.el-main {
  background: url(../assets/bj.png);
  background-size: 100%, 100%;
}

.el-main {
  padding: 0;
}

.el-menu.el-menu--horizontal {
  border: none;
}

.el-menu--horizontal > .el-menu-item.is-active,
.el-menu--horizontal > .el-menu-item {
  border: none;
}
</style>
