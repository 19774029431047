<template>
	<div class="login_container">
		<div class="login_box">
			<div class="header">
				<div class="left">
					<img src="../assets/logo.png" mode="" />
					<span>欢迎加入</span>
				</div>
				<div class="right">
					<p>已有账号? <span @click="login()">马上登录</span></p>
					<p></p>
				</div>
			</div>

			<div class="head">找回密码</div>

			<!-- 登录表单区域 -->
			<el-form ref="loginFormRef" :model="login_form" :rules="loginRules" class="login_form">
				<!-- 手机号 -->
				<el-form-item prop="phone" class="list">
					<input v-model="login_form.phone" placeholder="请输入手机号" class="input" />
				</el-form-item>

				<!-- 验证码 -->
				<el-form-item prop="smsCode" class="list">
					<input placeholder="请输入验证码" v-model="login_form.smsCode" autocomplete="off" class="input"
						style="width: 70%" />

					<span v-show="show" @click="sendCode('login_form')">获取短信验证码</span>
					<span v-show="!show">已发送 {{ count }}s</span>
				</el-form-item>

				<!-- 密码 -->
				<el-form-item prop="password" class="list">
					<input v-model="login_form.password" placeholder="请输入密码" class="input" />
				</el-form-item>

				<div class="word">
					<el-checkbox v-model="checked"></el-checkbox>
					我已阅读并接受大工（青岛）研究院<span>版权声明</span>和<span>隐私保护条款</span>
				</div>
				<div class="btn" @click="register()">下一步</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import service from '@/utils/request.js';
	export default {
		data() {
			return {
				checked: false,
				show: true,
				count: 60,
				login_form: {
					phone: '',
					smsCode: '',
					checkMove: '',
					password: ''
				},
				loginRules: {
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					smsCode: [{
						required: true,
						message: '请输入手机验证码',
						trigger: 'blur'
					}],
					password: [{
							required: true,
							type: 'string',
							message: '登录密码必填',
							trigger: ['blur', 'change']
						},
						{
							pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
							message: '登录密码至少6位数字或英文或数字英文组合',
							trigger: ['blur', 'change']
						}
					],
				},
			}
		},
		methods: {
			//登录
			login() {
				this.$router.push('/loginTwo')
			},
			//发送短信验证码
			sendCode(formName) {
				let phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/
				if (!phoneReg.test(this.login_form.phone) || !this.login_form.phone) {
					this.$message.error({
						message: '手机格式不正确',
						type: 'warning',
					})
					return false;
				}
				//发送短信验证码接口
				this.$http.post('/api/platform/code', {
					phone: this.login_form.phone,
				}).then(response => {
					if (response.status == 200) {
						console.log(response.data)
						this.show = false
						let timeId = setInterval(() => {
							if (this.count <= 0) {
								this.show = true
								clearInterval(timeId)
								this.count = 60
							} else {
								this.count--
							}
						}, 1000)
					}
					// else{
					// 	 //this.$message.error(response.data.message)
					// }
				});
			},
			//注册
			register() {
				this.$refs.loginFormRef.validate(async (valid) => {
					if (!valid) return
					if (!this.checked) {
						this.$message.error("请勾选隐私保护条款");
						return false;
					}
					this.$http.post('/api/platform/pwd', {
						phone: this.login_form.phone,
						password: this.login_form.password,
						code: this.login_form.smsCode
					}).then(response => {
						// 处理响应数据
						if (response.status == 200) {
							this.$message.success('找回密码成功');
							setTimeout(function() {
								this.$router.push('/loginTwo')
							}, 100);
						}
						// else{
						// 	 this.$message.error(response.data.message)
						// }
					});
				});
			},
		},
		components: {},
	}
</script>

<style scoped lang="less">
	.login_container {
		height: 100%;
		background: url(../assets/bj.png);
		background-size: cover;

		.login_box {
			width: 500px;
			height: 400px;
			background-color: #fff;
			border-radius: 10px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			position: relative;

			.header {
				width: 100%;
				height: 100px;
				position: absolute;
				top: -90px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #fff;

				.left {
					display: flex;
					align-items: center;

					img {
						width: 130px;
						margin-right: 10px;
					}

					span {
						font-size: 18px;
					}
				}

				.right {
					font-size: 14px;

					span {
						color: orange;
						margin-left: 10px;
					}
				}
			}

			.head {
				text-align: center;
				width: 100%;
				height: 45px;
				line-height: 45px;
				border-bottom: 1px solid #444;
			}
		}
	}

	.login_form {
		width: 80%;
		margin: 30px auto;
	}


	.word {
		font-size: 14px;
		margin-top: 10px;
	}

	.btn {
		margin-top: 30px;
		width: 100%;
		height: 45px;
		background-color: #c4c1c1;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 14px;
	}

	.list {
		padding: 0 15px;
		box-sizing: border-box;
		border-bottom: 0.5px solid #999;
		padding: 5px 0;
		box-sizing: border-box;
	}

	.input {
		width: 100%;
		height: 100%;
		border: none;
		outline: none;
		background-color: #fff;
		text-indent: 1em;
	}
</style>