<template>
	<div class="box">
		<div class="main">
			<div class="header">上传附件</div>
			<div class="head">
				<div class="top">下载导入模板，根据模板提示完善内容</div>
				<div class="down" @click="downFn">
					<img src="../assets/muban.png" />
				</div>
			</div>
			<div class="main">
				<div class="top">上传完善好的内容，支持上传文件为：JPG,PDF,PPT</div>
				<div class="down">
					<img src="../assets/icon.png" />
					<div class="select">
						<el-upload class="upload-demo" :action="uplodeUrl" :on-preview="handlePreview"
							:on-remove="handleRemove" multiple :limit="1" :on-exceed="handleExceed"
							:on-success="handleSuccess" :file-list="fileList" :headers="{ Authorization: getToken() }">
							<!-- :before-remove="f" -->
							点击上传
						</el-upload>
					</div>
					<div class="word">支持上传文件为：JPG,PDF,PPT,WORD</div>
				</div>
			</div>
			<!-- <div class="footer">
				<img src="../assets/baocun.png" alt="" />
				<img src="../assets/quxiao.png" alt="" />
			</div> -->
		</div>
	</div>
</template>

<script>
	import service from '@/utils/request.js';
	import cofig from '@/utils/config.js'
	export default {
		props: {},
		data() {
			return {
				uplodeUrl: cofig.baseUrl + '/api/upload/file',
				fileList: [],
			}
		},
		methods: {
			// 下载研学模板
			downFn() {
				window.location.href = "https://admin.qddgzs.cn/研究性学习报告模板.docx";
			},
			//当用户移除一个已上传的文件时，这个方法会被调用。它接收两个参数：
			//file表示被移除的文件对象，fileList表示当前已上传的文件列表
			handleRemove(file, fileList) {
				console.log(file, fileList)
			},
			//当用户点击预览一个已上传的文件时，这个方法会被调用。
			//它接收一个参数：file表示被预览的文件对象
			handlePreview(file) {
				console.log(file)
			},
			//当用户尝试上传超过限制数量的文件时，这个方法会被调用。它接收两个参数：
			//files表示用户尝试上传但超出限制的文件列表，fileList表示当前已上传的文件列表
			handleExceed(files, fileList) {
				//已选择了 ${files.length} 个文件，
				this.$message.warning(
					`当前限制选择 1 个文件，共选择了 ${
                files.length + fileList.length
              } 个文件，如需上传新的文件，请刷新当前页面或删除已上传文件。`
				)
			},
			//在用户尝试移除一个已上传的文件之前，这个方法会被调用。它接收两个参数：
			//file表示被移除的文件对象，fileList表示当前已上传的文件列表。这个方法返回一个Promise，
			//如果Promise resolve为true，则允许移除文件；否则，不允许移除
			beforeRemove(file, fileList) {
				//return this.$confirm(`确定移除 ${file.name}？`)
				return this.$confirm(`确定移除 ${file.name}？`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 用户点击了确定按钮，执行删除文件的操作
					console.log('用户确认移除文件');
					// 在这里添加你的删除文件逻辑
				}).catch(() => {
					// 用户点击了取消按钮，不执行任何操作
					console.log('用户取消移除文件');
				});
			},
			//上传成功，服务器返回的数据
			handleSuccess(response, file, fileList) {
				console.log('上传成功，服务器返回的数据为：', response);
				if (response && response.hasOwnProperty('doc_path')) {
					//保存上传的研学报告
					this.$http.post('/api/user/update', {
						"learn_files": response.doc_path
					}).then(response => {
						// 处理响应数据
						if (response.status == 200) {
							this.$message.success('上传报告成功')
						}
					});
				} else {
					console.log("response.doc_path不存在");
				}
			},
			// 获取token的逻辑
			getToken() {
				const token = window.sessionStorage.getItem('token');
				return `Bearer ${token}`;
			},
		},
		components: {

		},
	}
</script>

<style scoped lang="less">
	.box {
		width: 100%;
		min-height: 100%;
		background-color: #fff;

		.main {
			width: 25%;
			margin: 0 auto;
			padding: 50px 0;
			box-sizing: border-box;
			font-size: 14px;

			.header {
				font-weight: 600;
			}

			.head {
				width: 100%;
				background-color: #f9fafe;
				border: 0.5px solid #999;
				padding: 20px;
				box-sizing: border-box;
				border-radius: 10px;
				margin: 20px 0;

				.top {
					color: #666;
				}

				.down {
					margin-top: 20px;

					img {
						width: 100px;
					}
				}
			}

			.main {
				width: 100%;
				background-color: #f9fafe;
				border: 0.5px solid #999;
				padding: 20px;
				box-sizing: border-box;
				border-radius: 10px;

				.top {
					color: #444;
				}

				.down {
					margin-top: 20px;
					border: 0.5px solid #999;
					border-radius: 10px;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 30px;
					box-sizing: border-box;

					img {
						width: 50px;
					}

					.select {
						padding: 3px 20px;
						border: 0.5px solid #999;
						border-radius: 5px;
						margin: 10px 0;
					}

					.word {
						font-size: 12px;
						color: #444;
					}
				}
			}

			.footer {
				margin-top: 30px;
				display: flex;
				justify-content: space-evenly;

				img {
					width: 100px;
				}
			}
		}
	}
</style>