<template>
  <div class="login_container">
    <div class="login_box">
      <div class="header">
        <div class="left">
          <img src="../assets/logo.png" mode="" />
          <span>欢迎加入</span>
        </div>
        <div class="right">
          <p>
            <span @click="password()" style="color: red">找回密码?</span>
            <span @click="register()">没有账号,立即注册</span>
          </p>
          <p></p>
        </div>
      </div>

      <div class="head">账号登录</div>

      <!-- 登录表单区域 -->
      <el-form
        ref="loginFormRef"
        :rules="loginRules"
        class="login_form"
        :model="login_form"
      >
        <!-- 姓名 -->
        <el-form-item prop="name" class="list">
          <input
            v-model="login_form.name"
            type="text"
            placeholder="请输入姓名"
            class="input"
          />
        </el-form-item>

        <!-- 手机号 -->
        <el-form-item prop="phone" class="list">
          <input
            v-model="login_form.phone"
            placeholder="请输入手机号"
            class="input"
          />
        </el-form-item>

        <!-- 验证码 -->
        <el-form-item prop="password" class="list">
          <input
            placeholder="请输入密码"
            v-model="login_form.password"
            class="input"
          />
        </el-form-item>

        <!-- <div class="word">
				  <el-checkbox v-model="checked"></el-checkbox>
				  下次自动登录
				</div> -->
        <div class="btn" @click="login()">登录</div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      count: 60,
      login_form: {
        phone: "",
        name: "",
        password: "",
      },
      loginRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            type: "string",
            message: "登录密码必填",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
            message: "登录密码至少6位数字或英文或数字英文组合",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    //登录
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return false;
        this.$http
          .post("/api/platform/login", {
            phone: this.login_form.phone,
            name: this.login_form.name,
            password: this.login_form.password,
          })
          .then((response) => {
            // 处理响应数据
            if (response.status == 200) {
              this.$message.success("登陆成功");
              let access_token = response.data.access_token;
              window.sessionStorage.setItem("token", access_token);
              window.sessionStorage.setItem("customerInfo", response.data.user);
              //获取expires_in时间
              let expiresIn = parseInt(response.data.expires_in);
              //设置token到期时间
              let expirationTime = new Date().getTime() + expiresIn * 1000;
              window.sessionStorage.setItem("expirationTime", expirationTime);
              //设置token提前五分钟到期时间：300秒
              let fiveExpiresIn = expiresIn - 300;
              let fivexpirationTime =
                new Date().getTime() + fiveExpiresIn * 1000;
              window.sessionStorage.setItem(
                "fivexpirationTime",
                fivexpirationTime
              );
              if (access_token) {
                this.$router.push("/home");
              }
            }
          });
      });
    },
    //注册
    register() {
      this.$router.push("/login");
    },
    password() {
      this.$router.push("/password");
    },
  },
  components: {},
};
</script>

<style scoped lang="less">
.login_container {
  height: 100%;
  background: url(../assets/bj.png);
  background-size: cover;

  .login_box {
    width: 500px;
    height: 400px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: relative;

    .header {
      width: 100%;
      height: 100px;
      position: absolute;
      top: -80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;

      .left {
        display: flex;
        align-items: center;

        img {
          width: 130px;
          margin-right: 10px;
        }

        span {
          font-size: 18px;
        }
      }

      .right {
        font-size: 14px;

        span {
          color: orange;
          margin-left: 10px;
        }
      }
    }

    .head {
      text-align: center;
      width: 100%;
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid #444;
    }
  }
}

.login_form {
  width: 80%;
  margin: 30px auto;
}

.word {
  font-size: 14px;
}

.btn {
  margin-top: 30px;
  width: 100%;
  height: 45px;
  background-color: #206ad7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
}

.list {
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 0.5px solid #999;
  padding: 5px 0;
  box-sizing: border-box;
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: #fff;
  text-indent: 1em;
}
</style>
