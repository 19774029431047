<template>
	<div class="box">
		<div class="main">
			<div class="list">
				<img src="../assets/xiazai.png" alt="" />
			</div>
			<div class="footer" @click="downFn">
				<img src="../assets/muban.png" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {

		},
		data() {
			return {

			}
		},
		methods: {
			//下载研学模板
			downFn() {
				window.location.href = "https://qddgpc.xmsbj.cn/研究性学习报告模板.docx";
			},
		},
		components: {

		},
	}
</script>

<style scoped lang="less">
	.box {
		width: 100%;
		min-height: 100%;
		background-color: #fff;

		.main {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 50px 100px;
			box-sizing: border-box;
			overflow: hidden;

			.list {
				width: 40%;
				margin: 0 auto;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.footer {
				width: 100%;
				margin-top: 30px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-evenly;

				img {
					width: 120px;
				}
			}
		}
	}
</style>