/****   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import {
	Message
} from 'element-ui';
import cofig from './config.js'
import router from '@/router/index'; // 假设您的路由配置在router.js文件中

//1. 创建新的axios实例，
const service = axios.create({
	// 公共接口--这里注意后面会讲
	baseURL: cofig.baseUrl,
	// 超时时间 单位是ms，这里设置了3s的超时时间
	timeout: 3 * 1000
})


// 2.请求拦截器
// service.interceptors.request.use(config => {
// 	//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
// 	config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
// 	config.headers = {
// 		'Access-Control-Allow-Origin': '*', //或者指定特定的域名
// 		'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS', //
// 		'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Authorization', //配置请求头
// 		'Content-Type': 'application/json', //配置请求头
// 	}

// 	//注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
// 	const token = window.sessionStorage.getItem('token');
// 	if (token) {
// 		config.headers.Authorization = `Bearer ${token}`;
// 		//config.headers['Authorization'] = `Bearer ${token}`;
// 	}
// 	return config
// }, error => {
// 	Promise.reject(error)
// })

// 3.响应拦截器
service.interceptors.response.use(response => {
	//接收到响应数据并成功后的一些共有的处理，关闭loading等
	return response;
}, error => {
	/***** 接收到异常响应的处理开始 *****/
	if (error && error.response) {
		// 1.公共错误处理
		if (error.response.status == 444) {
			Message.error(error.response.data.message)
			router.push('/loginTwo'); // 假设您有一个名为loginTwo的路由
		}
		error.message = error.response.data.message
	} else {
		// 超时处理
		error.message = JSON.stringify(error).includes('timeout') ? '服务器响应超时，请刷新当前页' : '连接服务器失败'
	}
	/***** 处理结束 *****/
	Message.error(error.message)
	//如果不需要错误处理，以上的处理过程都可省略
	return Promise.resolve(error.response)
})

//4.导入文件
export default service