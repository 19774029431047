import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import TreeTable from 'vue-table-with-tree-grid'
//import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import {
	Message
} from 'element-ui';
import axios from 'axios'
axios.defaults.baseURL = 'https://admin.qddgzs.cn'
axios.interceptors.request.use(config => {
	// 在这里添加请求拦截器的逻辑，例如设置请求头等
	// config.headers = {
	// 	'Access-Control-Allow-Origin': '*', //或者指定特定的域名
	// 	'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS', //
	// 	'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Referrer-Policy, Authorization', //配置请求头
	// 	'Content-Type': 'application/json', //配置请求头
	// 	'Referrer-Policy': 'no-referrer', //配置请求头
	// }
	//注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
	const token = window.sessionStorage.getItem('token');
	if (token) config.headers.Authorization = `Bearer ${token}`
	return config;
}, error => {
	// 对请求错误做些什么
	return Promise.reject(error)
});
axios.interceptors.response.use(response => {
	// 在这里添加响应拦截器的逻辑，例如处理响应数据等
	return response
}, error => {
	// 对响应错误做些什么
	/***** 接收到异常响应的处理开始 *****/
	if (error && error.response) {
		// 1.公共错误处理
		if (error.response.status == 444) {
			Message.error(error.response.data.message)
			router.push('/loginTwo'); // 假设您有一个名为loginTwo的路由
		}
		error.message = error.response.data.message
	} else {
		// 超时处理
		error.message = JSON.stringify(error).includes('timeout') ? '服务器响应超时，请刷新当前页' : '连接服务器失败'
	}
	/***** 处理结束 *****/
	Message.error(error.message)
	//如果不需要错误处理，以上的处理过程都可省略
	return Promise.resolve(error.response)
});
Vue.prototype.$http = axios

// import service from '@/utils/request.js';
// Vue.prototype.$http = service;

Vue.config.productionTip = false
Vue.component('tree-table', TreeTable)
Vue.filter('dateFormat', function(originVal) {
	const dt = new Date(originVal)
	const y = dt.getFullYear()
	const m = (dt.getMonth() + 1 + '').padStart(2, '0')
	const d = (dt.getDate() + '').padStart(2, '0')
	const hh = (dt.getHours() + '').padStart(2, '0')
	const mm = (dt.getMinutes() + '').padStart(2, '0')
	const ss = (dt.getSeconds() + '').padStart(2, '0')
	return `${y}- ${m} -${d} ${hh}:${mm}:${ss}`
})

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')