<template>
  <div class="box"></div>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
  methods: {},
  components: {},
}
</script>

<style scoped lang="less">
.box {
  background: url(../assets/bj.png);
  background-size: 100%, 100%;
}
</style>
