<template>
	<div>
		<div class="header">
			<p>青岛大工成长规划管理中心是大连理工大学（青岛）研究院下设的高考升学规划部门，为高中学生提供选科指导、</p>
			<p>志愿填报、强基计划、综合评价、港澳升学、云平台填写、艺考、留学等，助力考生升学无忧！</p>
			<p>联系电话:19026805723、16552263612</p>
		</div>
		<div class="main">
			<el-card class="box-card">
				<el-form ref="loginFormRef" :rules="loginRules" :model="login_form" label-width="80px"
					label-position="left">
					<el-form-item required prop="name" label="姓名" class="list">
						<input v-model="login_form.name" class="input" placeholder="请输入姓名" />
					</el-form-item>
					<el-form-item required prop="school" label="学校" class="list">
						<input v-model="login_form.school" class="input" placeholder="请输入学校" />
					</el-form-item>
					<el-form-item required prop="grade" label="年级" class="list">
						<input v-model="login_form.grade" class="input" placeholder="请输入年级" />
					</el-form-item>
					<el-form-item required prop="id_card" label="身份证号" class="list">
						<input v-model="login_form.id_card" class="input" placeholder="请输入身份证号" />
					</el-form-item>
					<el-form-item prop="topics_id" label="创新课题" class="list">
						<el-select v-model="login_form.topics_id" @change="handleSelectChange" placeholder="请选择"
							class="my-el-select">
							<el-option v-for="iteam in actions" :key="iteam.id" :label="iteam.name"
								:value="iteam.id"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item prop="topics_id_learn" label="研学课题" class="list">
						<el-select v-model="login_form.topics_id_learn" @change="handleSelectChangeTwo"
							placeholder="请选择" class="my-el-select">
							<el-option v-for="iteam in actionsTwo" :key="iteam.id" :label="iteam.name"
								:value="iteam.id"></el-option>
						</el-select>
					</el-form-item>
					<div class="btn" @click="submit()">确定</div>
				</el-form>
			</el-card>
		</div>
	</div>
</template>

<script>
	import service from '@/utils/request.js';
	export default {
		props: {},
		data() {
			return {
				login_form: {
					name: '',
					topics_id: '', //创新课题id
					topicsName: "", //创新课题名称

					topics_id_learn: "", //研学课题
					//topicsLearnName: "", //研学课题

					school: '', //学校
					grade: '', //班级
					id_card: '' //身份证
				},
				//创新课堂列表
				actions: [],
				//研学课堂列表
				actionsTwo: [],
				// 校验规则
				loginRules: {
					name: [{
						required: true,
						message: '姓名必填',
						trigger: ['blur', 'change']
					}],
					school: [{
						required: true,
						message: '学校必填',
						trigger: ['blur', 'change']
					}],
					grade: [{
						required: true,
						message: '年级必填',
						trigger: ['blur', 'change']
					}],
					id_card: [{
						required: true,
						message: '身份证必填',
						trigger: ['blur', 'change']
					}],
				},
			}
		},
		//第一次加载
		mounted(e) {
			//课题列表&个人信息
			this.getServiceData();
		},
		methods: {
			//创新课题
			handleSelectChange(value) {
				this.login_form.topics_id = value
				let select = this.actions.find(item => item.id === value);
				this.login_form.topicsName = select.name;
			},
			//研学课题
			handleSelectChangeTwo(value) {
				this.login_form.topics_id_learn = value
				let select = this.actionsTwo.find(item => item.id === value);
				//this.login_form.topicsLearnName = select.name;
			},
			//获取数据
			async getServiceData() {
				let that = this;
				//研学课题列表
				this.$http.post('/api/user/topics', {
					type: 1
				}, true).then(response => {
					// 处理响应数据
					if (response.status == 200) {
						that.actions = response.data;
					}
				});
				//创新课题列表
				this.$http.post('/api/user/topics', {
						type: 2
					},
					true).then(response => {
					// 处理响应数据
					if (response.status == 200) {
						that.actionsTwo = response.data;
					}
				});

				//个人信息
				this.$http.post('/api/user/info', {}).then(response => {
					// 处理响应数据
					if (response.status == 200) {
						that.login_form = response.data;
						this.login_form.topics_id = that.login_form.topics_id; //创新课题id
						//this.login_form.topicsName = that.login_form.topicsName; //创新课题名称

						this.login_form.topics_id_learn = that.login_form.topics_id_learn; //研学课题id
						//this.login_form.topicsLearnName = that.login_form.learn_files; //研学课题名称
					}
				});

			},
			//提交验证
			submit() {
				let that = this;
				that.$refs.loginFormRef.validate(async (valid) => {
					if (!valid) return
					let params = {
						name: that.login_form.name,
						school: that.login_form.school,
						grade: that.login_form.grade,
						id_card: that.login_form.id_card,
						topics_id: that.login_form.topics_id,
						topics_id_learn: that.login_form.topics_id_learn,
					}
					this.$http.post('/api/user/update', params).then(response => {
						// 处理响应数据
						if (response.status == 200) {
							that.$message.success('修改成功');
						}
					});
				});
			}
		},
		components: {

		},
	}
</script>

<style scoped lang="less">
	.header {
		width: 100%;
		height: 300px;
		background: url(../assets/meaaageBj1.jpg);
		background-size: 100%, 100%;
		text-align: center;
		color: #fff;
		padding: 60px 0;
		box-sizing: border-box;
		line-height: 30px;
	}

	.main {
		width: 100%;
		height: 400px;
		background: #f4f7fd;
		position: relative;

		.box-card {
			width: 500px;
			height: 400px;
			position: absolute;
			top: 0;
			left: 50%;
			background-color: #f5f8fb;
			transform: translate(-50%, -35%);
			border-radius: 15px;
			padding: 0 15px;
			box-sizing: border-box;
		}
	}

	.list {
		padding: 0 15px;
		box-sizing: border-box;
		border-bottom: 0.5px solid #999;
		margin: 7px;
	}

	.input {
		width: 100%;
		height: 100%;
		border: none;
		outline: none;
		background-color: #f5f8fb;
		text-indent: 1em;
	}

	.my-el-select /deep/ .el-input .el-input__inner {
		border-color: #f5f8fb !important;
		background-color: #f5f8fb;
	}

	.btn {
		width: 97%;
		height: 45px;
		margin: 15px auto;
		background-color: #206ad7;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 14px;
	}
</style>